import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function TaxOptimization() {
  return (
    <div className="w-full grid place-items-center mt-40">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Tax Optimization
                </motion.h2>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden float-right">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
          />
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Although all business owners want to minimize the tax that
                  they pay, not everyone can - or wants to - spend the time and
                  energy to invest in researching and structuring their business
                  affairs to optimize their tax obligations.
                  <br />
                  <br />
                  We have learned from our experience with clients that
                  developing a thorough approach to tax planning is the most
                  effective starting point. That means focusing on the following
                  key elements:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                  key="co"
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold ">Developing a tax strategy</h3>
                    </div>
                    <p className="pl-6">
                      Since each business, shareholder, family, and entrepreneur
                      is unique, we must develop an equally unique planning
                      strategy based on specific business and ownership
                      objectives.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Implementing the appropriate administration{" "}
                      </h3>
                    </div>
                    <p className="pl-6">
                      Developing a plan and tactics is only one step in a tax
                      optimization strategy; you need the proper administration
                      and systems in place to support, operate, track, document
                      and follow through.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">Monitor and adjust </h3>
                    </div>
                    <p className="pl-6">
                      No plan lasts a lifetime and, as your business evolves, so
                      will your tax strategies
                    </p>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  There are several key considerations and problems we see on a
                  regular basis when providing tax strategy, planning and
                  ongoing tax support. These include:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Initial tax structures must have sufficient flexibility
                        to accommodate unforeseen circumstances, such as
                        economic downturns, unexpected sale opportunities and
                        family changes.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        An initial plan that works for one set of circumstances
                        can be costly to change for new situations or even
                        create unnecessary and unexpected tax liabilities.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Tax legislation is unclear, and all tax plans involve a
                        certain amount of risk; therefore, it is critical that
                        business owners keep their unique risk levels.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Optimizing the tax function is an important objective for any
                  organization – no matter how big or small. A proactive
                  approach to developing a tax strategy will help prepare your
                  business for long-term success.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>
      </section>
    </div>
  );
}

export default TaxOptimization;
