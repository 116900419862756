import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

function CancerAwareness() {
  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
                className="mb-24"
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Breast Cancer Awareness
                </motion.h2>
                <motion.h4 className="mb-8 md:mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide">
                  Get your pink ribbons on! 🎀
                </motion.h4>
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  To honor the fighters who are suffering, we at Nobani & Co and
                  Nobani & Marouf hosted a breast cancer awareness campaign. it
                  included a presentation on facts (should know), importance,
                  prevention methods and uniting our stand as one to support the
                  patients by donations and other supporting activities.
                </motion.p>
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  And our message to you is to early check at the nearest clinic
                  and spread the awareness between your beloved ones as we did.
                </motion.p>
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  We sincerely give our gratitude to Nahno Organization for
                  documenting this event to spread the awareness to a wider
                  range of people.
                </motion.p>
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Your health matters! Stay safe.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <Carousel swipeable>
            <div>
              <img src="https://i.ibb.co/TWmYVkX/IMG-1086.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/hmDZzCN/PHOTO-2021-10-11-12-57-05.jpg" />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default CancerAwareness;
