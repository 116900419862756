import React from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Button from "../atoms/Button";

function AboutUs() {
  return (
    <section className="w-full relative mb-24 lg:px-8 xl:px-16 bg-primary-dark">
      <div className="relative isolate w-full flex flex-col justify-center items-center z-20 py-16 lg:py-32">
        <div className="container px-6 xl:p-16 flex flex-col items-center justify-center">
          <div className="grid grid-flow-row lg:grid-cols-10 lg:gap-8">
            <div className="flex flex-col justify-end col-span-4">
              <InView threshold={0.7} triggerOnce>
                {({ ref, inView }) => (
                  <motion.h2
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="capitalize border-b border-secondary-light pb-2 text-primary mb-8 text-2xl md:text-4xl font-bold tracking-wide"
                  >
                    Our Partner's Letter
                  </motion.h2>
                )}
              </InView>
              <InView threshold={0.7} triggerOnce>
                {({ ref, inView }) => (
                  <motion.h4
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="capitalize text-secondary-lighter text-3xl sm:text-5xl lg:text-4xl xl:text-5xl font-bold tracking-widest mb-8 lg:mb-0"
                  >
                    I look forward to hearing from you,
                  </motion.h4>
                )}
              </InView>

              <p></p>
            </div>

            <InView threshold={1} triggerOnce>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={
                    inView
                      ? { opacity: 1, scale: 1 }
                      : { opacity: 0, scale: 0.9 }
                  }
                  transition={{ duration: 0.8 }}
                  className="flex flex-col justify-center text-left text-secondary-light col-span-4"
                >
                  <p className="text-sm md:text-lg font-bold max-w-screen-lg leading-relaxed ">
                    In a world full of uncertainties and doubtfulness we, at
                    Nobani & Co., thrive for the purpose of creating a better
                    business world lead by satiability and justice through
                    putting our resourceful intellect into work
                  </p>
                </motion.div>
              )}
            </InView>

            <div className="lg:relative col-span-2 flex flex-col items-center justify-center">
              <div className="lg:relative w-full h-full">
                <InView threshold={1} triggerOnce>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, y: -100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="absolute top-10 sm:top-12 right-4 transform translate-x-80 lg:left-1/3 lg:top-0 xl:top-0 xl:left-1/3  w-50px lg:w-100px rounded-full lg:overflow-hidden lg:bg-secondary-lighter"
                    >
                      <img
                        src="./avatars/avatar-03.svg"
                        alt=""
                        className="w-full h-full"
                      />
                    </motion.div>
                  )}
                </InView>

                <InView threshold={1} triggerOnce>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, x: -100 }}
                      animate={
                        inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="absolute top-12 right-44 lg:-right-8 lg:top-full xl:left-2vw xl:top-3/4 2xl:left-8 2xl:top-full 4xl:top-24 w-50px lg:w-100px lg:rounded-full overflow-hidden lg:bg-secondary-lighter hidden md:block"
                    >
                      <img
                        src="./avatars/avatar-01.svg"
                        alt=""
                        className="w-full h-full"
                      />
                    </motion.div>
                  )}
                </InView>

                <InView threshold={1} triggerOnce>
                  {({ ref, inView }) => (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, x: 100 }}
                      animate={
                        inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="absolute top-12 right-24 lg:right-1/2 lg:top-24 xl:-right-6vw 2xl:-right-2vw 4xl:right-0 w-50px lg:w-100px lg:rounded-full overflow-hidden lg:bg-secondary-lighter hidden md:block"
                    >
                      <img
                        src="./avatars/avatar-02.svg"
                        alt=""
                        className="w-full h-full"
                      />
                    </motion.div>
                  )}
                </InView>
              </div>
              <InView threshold={1} triggerOnce>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                  >
                    <Button
                      classes="mt-8 lg:mt-16 lg:mr-16 xl:mr-0 lg:relative lg:top-20 lg:left-2vw 4xl:left-1vw 4xl:top-24 "
                      isRounded
                    >
                      Read more
                    </Button>
                  </motion.div>
                )}
              </InView>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
