import React from "react";
import { useSelector } from "react-redux";

function TheFooter() {
  const company = useSelector((state) => state.globalVariables.company);

  return (
    <section className="w-full bg-primary text-secondary-lighter flex flex-col justify-start items-center px-6 md:px-16 pt-16 md:pt-24 z-10">
      <div className="container grid grid-flow-row md:grid-flow-col md:mb-12">
        {/* <div className="mb-8 md:mb-0"> */}
        {/* <h3 className=" text-xl md:text-2xl font-bold text-secondary-lighter tracking-wider mb-2 md:mb-6">
            Careers
          </h3> */}
        {/* <ul className="flex flex-col items-start justify-center">
            <li className="my-1 md:my-2 text-base md:text-lg cursor-pointer transition text-secondary-lighter  transform hover:scale-105 font-medium ">
              Lorem ipsum dolor sit
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg cursor-pointer transition text-secondary-lighter  transform hover:scale-105 font-medium ">
              Lorem, ipsum dolor.
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg cursor-pointer transition text-secondary-lighter  transform hover:scale-105 font-medium ">
              Lorem ipsum dolor sit amet.
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg cursor-pointer transition text-secondary-lighter  transform hover:scale-105 font-medium ">
              Lorem, ipsum.
            </li>
          </ul> */}
        {/* </div> */}
        <div className="mb-8 md:mb-0">
          <h3 className=" text-xl md:text-2xl font-bold text-secondary-lighter tracking-wider mb-2 md:mb-6">
            Contact Information
          </h3>
          <ul>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              P.O. Box 1219 Amman , 11118 Jordan
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              Tel: +962 6 551 5036/8
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              Fax: +962 6 553 8618
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              info@nobani.com
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              www.nobani.com
            </li>
          </ul>
        </div>
        <div className="w-full mb-4 md:mb-0">
          <h3 className=" text-xl md:text-2xl font-bold text-secondary-lighter tracking-wider mb-2 md:mb-6">
            Location
          </h3>
          <ul>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              Amman, Jordan
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              Wadi Saqra, Al Sharif Nasser Bin Jamil Street
            </li>
            <li className="my-1 md:my-2 text-base md:text-lg text-secondary-lighter">
              Al-Mirad Building #39, 2/Floor
            </li>
          </ul>
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13538.323597077288!2d35.886061!3d31.9722647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8fdc06fa31694750!2sNobani%20%26%20Co.!5e0!3m2!1sen!2sjo!4v1635584958912!5m2!1sen!2sjo"
            title="Nobani & Co"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            className="w-full h-72 mt-6"
          ></iframe> */}
        </div>
      </div>
      <hr style={{ width: "90%", height: "1px", opacity: 0.5 }} />
      <div className="container mt-12 pb-8">
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="mb-8 md:mb-0">
            <p className="text-sm md:text-base font-bold">
              All rights reserved. Nobani company &copy; 2021
            </p>
          </div>
          <ul className="flex justify-center items-center mb-8 md:mb-0">
            <li className="mx-4 md:mx-4 cursor-pointer text-4xl">
              <a
                href={`${
                  company === "co"
                    ? "https://www.facebook.com/NobaniCo/"
                    : "https://www.facebook.com/nobaniandmarouf/"
                }`}
                target="_blank"
                rel="noreferrer"
              >
                {/* <i className="fab fa-facebook-f"></i> */}
                {/* <img src="./logo/facebook.svg" alt="" /> */}
                <i className="fab fa-facebook-square text-3xl"></i>
              </a>
            </li>
            {/* <li className="mx-4 md:mx-6 cursor-pointer">
              <img src="./logo/twitter.svg" alt="" />
            </li> */}
            <li className="mx-4 md:mx-4 cursor-pointer">
              <a
                href={`${
                  company === "co"
                    ? "https://www.instagram.com/nobaniandco/"
                    : "https://www.instagram.com/nobaniandmarouf/"
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram text-3xl"></i>
                {/* <i className="fab fa-instagram-square text-4xl"></i> */}
              </a>
            </li>
            <li className="mx-4 md:mx-4 cursor-pointer">
              <a href="tel:+96265515036" target="_blank" rel="noreferrer">
                <i className="fab fa-whatsapp text-3xl"></i>
                {/* <i className="fab fa-whatsapp-square text-4xl"></i> */}
              </a>
            </li>
            <li className="mx-4 md:mx-4 cursor-pointer">
              <a
                href={`${
                  company === "co"
                    ? "https://www.linkedin.com/in/nobani-co-143017104/?originalSubdomain=jo"
                    : "https://www.linkedin.com/company/nobaniandmarouf/?originalSubdomain=jo"
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin text-3xl"></i>
              </a>
            </li>
          </ul>
          {/* <ul className="flex justify-between md:justify-start">
            <li className="md:ml-6 text-sm md:text-base font-bold cursor-pointer">
              Terms of Service
            </li>
            <li className="md:ml-6 text-sm md:text-base font-bold cursor-pointer">
              Policy Policy
            </li>
          </ul> */}
        </div>
      </div>
    </section>
  );
}

export default TheFooter;
