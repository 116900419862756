import React from "react";
import { motion } from "framer-motion";

function Button({ children, classes, isRounded, onClick, wrap, maxWidth }) {
  return (
    <motion.button
      onClick={onClick}
      className={`text-secondary-lighter transition-all ${
        wrap ? "" : "whitespace-nowrap"
      } ${maxWidth ? "max-w-125px" : ""}  ${
        isRounded ? "rounded-full" : ""
      } shadow-lg bg-primary text-2xs  px-4 py-3 sm:text-xs sm:px-6 sm:py-3 lg:px-8 lg:py-4 lg:text-base font-bold uppercase tracking-wider ${classes}`}
      whileHover={{ scale: "1.05" }}
      whileTap={{ scale: "0.95" }}
    >
      {children}
    </motion.button>
  );
}

export default Button;
