import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";

function AboutUs() {
  const company = useSelector((state) => state.globalVariables.company);

  return (
    <>
      <section className="relative mb-24 mt-24 flex justify-center items-center lg:px-12">
        <div id="aboutus" className="absolute -top-32"></div>

        <div className="relative isolate">
          <div className="absolute w-96  -right-1/3 rotate-90 md:rotate-0 md:-right-4  lg:-top-1/4 lg:-left-1/2 opacity-75 lg:w-full -z-10 transform scale-150 lg:scale-90">
            <img src="./illustrations/blob.svg" alt="" />
          </div>
          <div className="px-6 lg:px-0">
            <InView threshold={0.7} triggerOnce>
              {({ ref, inView }) => (
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  transition={{ duration: 0.8, staggerChildren: 3 }}
                >
                  <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                    About Us
                  </motion.h2>
                  <motion.h4 className="mb-8 md:mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide">
                    Thrive for creating a better business world
                  </motion.h4>
                </motion.div>
              )}
            </InView>
          </div>

          <div className="container relative isolate  w-full flex flex-col items-center">
            <div className="lg:grid lg:grid-cols-2">
              <InView threshold={0.4} triggerOnce>
                {({ ref, inView }) => (
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={
                      inView
                        ? { opacity: 1, scale: 1 }
                        : { opacity: 0, scale: 0.95 }
                    }
                    transition={{ duration: 0.6, delay: 0.6 }}
                    className="relative w-full z-10"
                  >
                    <img
                      src="./illustrations/company-amico.svg"
                      alt=""
                      className="w-full object-cover hidden md:block"
                    />
                  </motion.div>
                )}
              </InView>
              <div className="px-6 xl:p-16 flex flex-col justify-start">
                <div>
                  <InView threshold={0.5} triggerOnce>
                    {({ ref, inView }) => (
                      <AnimatePresence exitBeforeEnter>
                        {company === "co" ? (
                          <motion.div
                            ref={ref}
                            initial={{ opacity: 0, x: -100 }}
                            animate={
                              inView
                                ? { opacity: 1, x: 0 }
                                : { opacity: 0, x: -100 }
                            }
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.6 }}
                            key="co"
                          >
                            <motion.h3
                              ref={ref}
                              initial={{ opacity: 0, x: 100 }}
                              animate={
                                inView
                                  ? { opacity: 1, x: 0 }
                                  : { opacity: 0, x: 100 }
                              }
                              transition={{ duration: 0.8 }}
                              className="text-lg md:text-2xl font-medium mb-4 pl-2 border-l-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                            >
                              Nobani{" "}
                              <span className="text-primary"> &#38;</span> Co
                            </motion.h3>
                            <motion.p
                              ref={ref}
                              initial={{ opacity: 0, y: 100 }}
                              animate={
                                inView
                                  ? { opacity: 1, y: 0 }
                                  : { opacity: 0, y: 100 }
                              }
                              transition={{ duration: 0.8 }}
                              className="text-sm md:text-lg text-primary-dark font-medium leading-loose mb-8 md:mb-16"
                            >
                              Nobani and Co. was established in 1994, by
                              Mohammad Al Nobani offering Outsourcing,
                              Accounting, Tax, Training and Advisory Services
                              Company. It grew significantly until it became one
                              of the top ten in Jordan.
                              <br />
                              <br />
                              In a world full of uncertainties and doubtfulness
                              we, at Nobani & Co. thrive for the purpose of
                              creating a better business world lead by
                              satiability and justice through putting our
                              resourceful intellect into work. We are a team of
                              diverse talents and knowledge working as one for
                              the benefit of all. A mixture worldwide standards
                              and local principles is applied for best results
                              and higher quality of work.
                            </motion.p>
                          </motion.div>
                        ) : company === "marouf" ? (
                          <motion.div
                            ref={ref}
                            initial={{ opacity: 0, x: -100 }}
                            animate={
                              inView
                                ? { opacity: 1, x: 0 }
                                : { opacity: 0, x: -100 }
                            }
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.6 }}
                            key="marouf"
                          >
                            <motion.h3
                              ref={ref}
                              initial={{ opacity: 0, x: 100 }}
                              animate={
                                inView
                                  ? { opacity: 1, x: 0 }
                                  : { opacity: 0, x: 100 }
                              }
                              transition={{ duration: 0.8 }}
                              className="text-lg md:text-2xl font-medium mb-4 pl-2 border-l-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                            >
                              Nobani{" "}
                              <span className="text-primary"> &#38;</span>{" "}
                              Marouf
                            </motion.h3>
                            <motion.p
                              layout
                              ref={ref}
                              initial={{ opacity: 0, y: 100 }}
                              animate={
                                inView
                                  ? { opacity: 1, y: 0 }
                                  : { opacity: 0, y: 100 }
                              }
                              transition={{ duration: 0.8 }}
                              className="text-sm md:text-lg text-primary-dark font-medium leading-loose mb-8 md:mb-16"
                            >
                              Mohammad Al Nobani & Osama Marouf cooperated in
                              extending the company and establishing an
                              Assurance &Financial Advisory Company under the
                              name Nobani &Marouf, ever since, the company has
                              been constantly growing with Nobani &Co.
                              <br />
                              <br />
                              for over 27 years. Both companies are
                              concentrating their efforts on maintaining a high
                              standard of professional competences and services
                              that meets and exceeds clients’ expectations.
                            </motion.p>
                          </motion.div>
                        ) : null}
                      </AnimatePresence>
                    )}
                  </InView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
