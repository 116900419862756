import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../../../../atoms/Button";
import "./HeaderAction.scss";

const zoom = {
  hidden: {
    y: -25,
    opacity: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      type: "tween",
    },
  },
  exit: {
    y: 25,
    opacity: 0,
  },
};

function HeaderAction({ company, changeCompany, classes }) {
  return (
    <div id="action-button-container" className={classes}>
      <Button
        classes="action-button"
        isRounded
        onClick={changeCompany}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <AnimatePresence exitBeforeEnter>
          {company === "marouf" && (
            <motion.img
              src="./logo/and-co.png"
              alt=""
              className="max-w-40px md:max-w-65px"
              variants={zoom}
              initial="hidden"
              animate="visible"
              exit="exit"
              key="co"
            />
          )}
          {company === "co" && (
            <motion.img
              src="./logo/and-marouf.png"
              alt=""
              className=" max-w-60px md:max-w-100px"
              variants={zoom}
              initial="hidden"
              animate="visible"
              exit="exit"
              key="marouf"
            />
          )}
        </AnimatePresence>
      </Button>
    </div>
  );
}

export default HeaderAction;
