import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function Talent() {
  return (
    <div className="w-full grid place-items-center mt-40">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Talent
                </motion.h2>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden float-right">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
          />
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  The ongoing success of your business is dependent on the
                  strength of your people, and your ability to channel their
                  talent. In fact, concerns around the capabilities and
                  competencies of staff represent some of the key business
                  issues that arise in our conversations with clients.
                  <br />
                  <br />
                  Business owners face several challenges when it comes to
                  managing their human capital not only in terms of current
                  needs, but also as a part of their long-term strategic
                  planning:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Do I have the right resources to execute on the key
                        operational requirements of the business?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Do I have the right talent in the right positions?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Does my current organizational structure accommodate my
                        future?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>When is there value in outsourcing versus hiring? </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        What skills will my business need to move to the next
                        level?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        How do I retain talent in a competitive environment?
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Working with our clients, we hear that they deal with several
                  talent considerations, including:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Long term organizational management and talent
                        forecasting
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Employee engagement and retention</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Talent management and career development</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Skills training</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Management training</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Industry benchmarking and competitive compensation</p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Your approach to talent management will invariably influence
                  the future of your business. In today’s fluid job market, the
                  management of people has become one of the most significant
                  elements of overall business management.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>
      </section>
    </div>
  );
}

export default Talent;
