/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  theme: "dark",
  company: "co",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "theme":
      return { ...state, theme: action.data };
    case "company":
      return { ...state, company: action.data };
    default:
      return state;
  }
};
