import "./App.scss";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Services from "./pages/Services/Services";
import Blogs from "./pages/Blogs/Blogs";
import ContactUs from "./pages/ContactUs";

import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path={["/services", "/services/:service"]}
          exact
          component={Services}
        />
        <Route
          path={["/blogs", "/blogs/:category", "/blogs/:category/:blog"]}
          exact
          component={Blogs}
        />
        <Route path="/contact-us" exact component={ContactUs} />
      </Switch>
    </Router>
  );
}

export default App;
