import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";

import TheHeader from "../../components/organisms/TheHeader/TheHeader";
import AssuranceServices from "./subpages/AssuranceServices";
import TaxServices from "./subpages/TaxServices";
import BuisenessServices from "./subpages/BuisenessServices";
import FinancialServices from "./subpages/FinancialServices";
import TheFooter from "../../components/organisms/TheFooter";

function Services() {
  const company = useSelector((state) => state.globalVariables.company);

  return (
    <div className="w-full grid place-items-center">
      <TheHeader classes="bg-secondary-lighter text-white" />
      <section className="mt-40">
        <div className="nav">
          {company === "marouf" && (
            <>
              <Link to="/services/assurance" className="link">
                <div className="prev">
                  <i className="fas fa-user-shield"></i>
                </div>
                <div className="small">
                  <i className="fas fa-user-shield"></i>
                </div>
                <div className="full">
                  <div className="f1">Assurance Services</div>
                  <div className="f2">
                    We provide distinctively unique audit solutions for our
                    clients.
                  </div>
                </div>
              </Link>
              <Link to="/services/financial" className="link">
                <div className="prev">
                  <i className="fas fa-piggy-bank"></i>{" "}
                </div>
                <div className="small">
                  <i className="fas fa-piggy-bank"></i>{" "}
                </div>
                <div className="full">
                  <div className="f1">Financial Advisory Services</div>
                  <div className="f2">
                    We offer a full range of tax specialist advice to ensure
                    every aspect of your tax position is carefully managed.
                  </div>
                </div>
              </Link>
            </>
          )}
          {company === "co" && (
            <>
              <Link to="/services/buiseness" className="link">
                <div className="prev">
                  <i className="fas fa-briefcase"></i>
                </div>
                <div className="small">
                  <i className="fas fa-briefcase"></i>
                </div>
                <div className="full">
                  <div className="f1">Buiseness Advisory Services</div>
                  <div className="f2">
                    Businesses today face more challenges than ever. The field
                    is constantly shifting, with new markets, new competitors,
                    and new competing products.
                  </div>
                </div>
              </Link>
              <Link to="/services/tax" className="link">
                <div className="prev">
                  <i className="fas fa-comments-dollar"></i>
                </div>
                <div className="small">
                  <i className="fas fa-comments-dollar"></i>
                </div>
                <div className="full">
                  <div className="f1">Tax & Payroll Advisory Services</div>
                  <div className="f2">
                    We offer a full range of tax specialist advice to ensure
                    every aspect of your tax position is carefully managed.
                  </div>
                </div>
              </Link>
            </>
          )}
        </div>
      </section>
      <Switch>
        <Route
          path={
            company === "co"
              ? ["/services", "/services/buiseness"]
              : ["/services/buiseness"]
          }
          exact
          component={BuisenessServices}
        />
        <Route path="/services/tax" exact component={TaxServices} />
        <Route
          path={
            company === "marouf"
              ? ["/services", "/services/assurance"]
              : ["/services/assurance"]
          }
          exact
          component={AssuranceServices}
        />
      </Switch>
      <Route path="/services/financial" exact component={FinancialServices} />

      <TheFooter />
    </div>
  );
}

export default Services;
