import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function BuisinessTransition() {
  return (
    <div className="w-full grid place-items-center mt-40">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Business Transition
                </motion.h2>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden float-right">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
          />
        </div>
        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Whether you are a small, family-owned business or a large
                  private company with professional management, the
                  transitioning of your business through the course of your
                  stewardship is critical.
                  <br />
                  <br />
                  We typically see four different types of transition
                  circumstances:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Succession with a focus on long term family ownership
                        and management
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Transition with continued family ownership employing
                        professional management
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Family succession with an eye towards potential merger
                        or sale
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Transition from private ownership to public ownership
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Every business transition has its own unique challenges, but
                  through our experience with our private company clients, we
                  have identified several common questions:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Should the business continue to be family/stakeholder
                        owned?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>How do we transition?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>What is a fair value for the business?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        How do we compensate different families or different
                        family members?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        How will the family/stakeholders reach a consensus on a
                        succession plan?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>How do we maintain objectivity through the process?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Will the designated successors have the proper training?
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>What is the long-term strategic vision?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Do we have a contingency plan in place?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>What will post-transition look like?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>What are the financial and reporting arrangements?</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Have we completed an effective tax strategy?</p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  While there is a substantial need to focus on the transition
                  plan process, it is equally important to focus on how to
                  ‘protect and grow’ the value of the business. There are many
                  tools and tactics which typically focus on one of four key
                  areas:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold ">
                        Retain and expand the customer base through such methods
                        as:
                      </h3>
                    </div>
                    <ul className="pl-6">
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>
                          long term customer retention strategies (contractual
                          arrangements){" "}
                        </p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Expansion in new, but aligned markets</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Geographic growth </p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>
                          Supply chain integration (ensure your product or
                          service is relevant, unique and key to your supply
                          chain){" "}
                        </p>
                      </li>
                    </ul>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Protect and grow the value of the business assets:{" "}
                      </h3>
                    </div>
                    <ul className="pl-6">
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>
                          Maintaining capital asset productivity and extending
                          useful life
                        </p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Inventory management</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Capitalizing on real estate holdings</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Key talent retention and acquisition</p>
                      </li>
                    </ul>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Grow revenue through merger, acquisition or divestiture:
                      </h3>
                    </div>
                    <ul className="pl-6">
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Strategic business planning </p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Targeted acquisitions</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>financial modeling combined entities</p>
                      </li>
                    </ul>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Sustainable increases in bottom line:
                      </h3>
                    </div>
                    <ul className="pl-6">
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Process and continuous improvement strategies</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Resource management</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Technology solutions</p>
                      </li>
                      <li className="flex items-start justify-start">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p>Sale of non-core assets</p>
                      </li>
                    </ul>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>
      </section>
    </div>
  );
}

export default BuisinessTransition;
