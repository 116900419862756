import { motion } from "framer-motion";
import Backdrop from "../atoms/Backdrop";
import Button from "../atoms/Button";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

const Modal = ({ handleClose, title, text, isSuccess, isError }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal bg-white flex flex-col items-start justify-between m-auto px-8 py-12"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1
          className={`justify-self-start text-4xl font-bold ${
            isSuccess ? "text-primary" : ""
          } ${isError ? "text-red-600" : ""}`}
        >
          {title}
        </h1>
        <p className="text-xl">{text}</p>
        <Button
          isRounded
          onClick={handleClose}
          classes="self-end justify-self-end"
        >
          Close
        </Button>
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
