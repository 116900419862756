import React from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import ValueCard from "../molecules/ValueCard";

const values = [
  {
    title: "Integrity",
    iconSrc: "./icons/integrity.svg",
  },
  {
    title: "Transparency",
    iconSrc: "./icons/transparency.svg",
  },
  {
    title: "Respect",
    iconSrc: "./icons/respect.svg",
  },
  {
    title: "professionalism",
    iconSrc: "./icons/professionalism.svg",
  },
  {
    title: "accountability",
    iconSrc: "./icons/accountability.svg",
  },
];

function CardGrid() {
  return (
    <section className="container mx-auto mb-24 md:my-48 px-6 lg:px-16 tracking-tighter flex flex-col items-center justify-center">
      <div className="text-left w-full px-6 md:px-0">
        <InView threshold={0.7} triggerOnce>
          {({ ref, inView }) => (
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, x: -100 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
              transition={{ duration: 0.8 }}
              className="capitalize text-primary text-2xl md:text-4xl font-bold tracking-tighter mb-4"
            >
              Our Values
            </motion.h2>
          )}
        </InView>
        <InView threshold={0.7} triggerOnce>
          {({ ref, inView }) => (
            <motion.h4
              ref={ref}
              initial={{ opacity: 0, x: -100 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide max-w-prose self-start"
            >
              We believe in maintaining a close relationship with our
              stakeholders and provide high quality service delivery to our
              clients.{" "}
            </motion.h4>
          )}
        </InView>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-x-16 gap-y-8 lg:gap-y-16 lg:gap-x-80 text-primary-dark md:mt-24">
        {values.map((item, index) => (
          <InView threshold={0.1} triggerOnce key={index}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 100 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
              >
                <ValueCard
                  key={item}
                  title={item.title}
                  iconSrc={item.iconSrc}
                />
              </motion.div>
            )}
          </InView>
        ))}
      </div>
    </section>
  );
}

export default CardGrid;
