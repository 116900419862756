import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { changeCompany } from "../../store/actions/globalVariables";

function Vision() {
  const company = useSelector((state) => state.globalVariables.company);
  const dispatch = useDispatch();
  const toggleCompany = () => {
    const newCompanyName = company === "co" ? "marouf" : "co";
    dispatch(changeCompany(newCompanyName));
  };
  const NobaniMarouf = () => {
    return (
      <h4 className="text-sm md:text-xl text-primary-dark font-bold max-w-screen-lg leading-normal">
        The future of Nobani and Co has been set years ago to constantly improve
        internally and grow externally. <br />
        <br /> We have foreseen this progress years ago and we aim on keeping it
        this way. <br />
        <br />
        Using the modern tools and the updated accounting standards we tailor
        our services on the preferences of our clients and the considerations of
        the national requirements.
      </h4>
    );
  };
  const NobaniCo = () => {
    return (
      <h4 className="text-sm md:text-xl text-primary-dark font-bold max-w-screen-lg leading-normal">
        At Nobani and Marouf, we aim to achieve more than just our goals.
        <br />
        <br /> We engage, create, innovate, and customize to open a new world of
        opportunities.
        <br />
        <br /> By utilizing our abilities, and working as one, our team dreams
        beyond what is possible. <br />
        <br />
        The milestone is to become a national enterprise that employs
        international tools and standards to get the best outcome.
      </h4>
    );
  };
  return (
    <>
      <section className="relative isolate bg-opacity-30 w-full flex justify-center items-center mb-24 px-6 lg:px-16">
        <div className="absolute inset-0 -top-24 -z-20 opacity-50">
          <img src="./illustrations/blob-scene.svg" alt="" className="w-full" />
        </div>

        <div className="container grid grid-cols-1 md:grid-cols-2 md:px-0 md:py-16">
          <div className="flex flex-col justify-start">
            <div className="mb-8">
              <InView threshold={1} triggerOnce>
                {({ ref, inView }) => (
                  <motion.h2
                    ref={ref}
                    initial={{ opacity: 0, y: -50 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }
                    }
                    transition={{ duration: 0.8 }}
                    className="capitalize text-primary text-2xl md:text-4xl font-bold tracking-tighter lg:mb-8"
                  >
                    Our Vision
                  </motion.h2>
                )}
              </InView>

              {/* <InView threshold={0.6} triggerOnce>
                {({ ref, inView }) => (
                  <motion.ul
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="flex font-medium rounded-full overflow-hidden max-w-min "
                  >
                    <li
                      onClick={toggleCompany}
                      className={`${
                        company === "co"
                          ? "text-secondary-lighter bg-primary font-bold"
                          : "bg-secondary-light"
                      } cursor-pointer text-sm md:text-base hover:text-secondary-lighter hover:bg-primary bg-opacity-70 p-2 px-3 md:p-4 transition whitespace-nowrap`}
                    >
                      Nobani & Co
                    </li>
                    <li
                      onClick={toggleCompany}
                      className={`${
                        company === "marouf"
                          ? "text-secondary-lighter bg-primary font-bold"
                          : "bg-secondary-light"
                      } cursor-pointer text-sm md:text-base hover:text-secondary-lighter hover:bg-primary bg-opacity-70 p-2 px-3 md:p-4 transition whitespace-nowrap`}
                    >
                      Nobani & Marouf
                    </li>
                  </motion.ul>
                )}
              </InView> */}
            </div>

            <InView threshold={0.5} triggerOnce>
              {({ ref, inView }) => (
                <AnimatePresence exitBeforeEnter>
                  {company === "co" ? (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, x: -100 }}
                      animate={
                        inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                      }
                      exit={{ opacity: 0, x: -100 }}
                      transition={{ duration: 0.6 }}
                      key="co"
                    >
                      <NobaniCo />
                    </motion.div>
                  ) : company === "marouf" ? (
                    <motion.div
                      ref={ref}
                      initial={{ opacity: 0, x: -100 }}
                      animate={
                        inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                      }
                      exit={{ opacity: 0, x: -100 }}
                      transition={{ duration: 0.6 }}
                      key="marouf"
                    >
                      <NobaniMarouf />
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              )}
            </InView>
          </div>
          <InView threshold={0.5} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 100 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
                transition={{ duration: 1 }}
                className="w-full hidden md:flex justify-center items-center"
              >
                <img
                  src="./illustrations/vision-amico.svg"
                  alt=""
                  className=" w-3/4"
                />
              </motion.div>
            )}
          </InView>
        </div>
      </section>
    </>
  );
}

export default Vision;
