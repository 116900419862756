import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function BuisenessServices() {
  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="xl:px-6 md:px-0 xl:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Business Advisory Services
                </motion.h2>
                <motion.h4 className="mb-2 md:mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide">
                  Businesses today face more challenges than ever. The field is
                  constantly shifting, with new markets, new competitors, and
                  new competing products.
                </motion.h4>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden flex justify-center items-center xl:block xl:float-right mb-12 xl:mb-0">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
            className="w-full"
          />
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                key="marouf"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Businesses today face more challenges than ever before. The
                  field is constantly shifting with new markets, new
                  competitors, new competing products, and other changes that
                  require continual changes in strategic and operational choices
                  for a business, to achieve and maintain a competitive
                  advantage.
                  <br />
                  The business must address all that while it is producing and
                  delivering its products or services to its clients. The pace
                  of change has been drastically increasing. Counting on the
                  status quo is no longer an option.
                </motion.p>
                <br />
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  This is where we come into work with our clients by taking a
                  huge load of them and help them stay focused on their clients.
                  We bring in world class expertise developed and honed over
                  years of experience in various markets and industries.
                  <br />
                  Our clients can select one or more of our services, where we
                  work with them to deliver seamless customized integrated
                  solutions that help them sharpen their respective competitive
                  advantage and ensure their continued success.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  1 <span className="text-primary">&bull;</span> 1 Business
                  Advisory Overview
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.ul key="marouf">
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Business Restructuring</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Feasibility Studies</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Policies & Procedures</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Risk Management</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Strategic Planning</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          Strategic Marketing and Marketing Plans
                        </p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Corporate Finance</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          Due diligence and Investigations
                        </p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Business Valuation</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Mergers and Acquisition</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Transaction Service</p>
                      </div>
                    </motion.li>
                  </motion.ul>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  2 <span className="text-primary">&bull;</span> Turnaround
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      There may be situations when you must formulate a
                      turnaround strategy for your business. The reasons may be
                      numerous like revenue downturn, high operating costs,
                      inadequate resources, lack of proper planning and many
                      more. We, at Nobani & Co., carry out the situation
                      analysis and discover the reasons for the prevailing
                      circumstances.
                      <br />
                      Not only this, but our experts also assist you to
                      formulate a turnaround strategy and rapidly transform your
                      business.
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p className="text-base">
                        Situation Analysis: We analyze the situation to identify
                        various reasons for the downturn. Under this, we
                        evaluate the financial soundness, operational
                        effectiveness, and marketing requirements of the firm.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p className="text-base">
                        Assess whether the business has the capacity to manage
                        change.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p className="text-base">Prepare a restructuring plan.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p className="text-base">
                        Suggest an action plan and ensure the availability of
                        adequate resources to implement it.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p className="text-base">
                        Stabilize and turnaround your operation and financial
                        position.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.h3
                ref={ref}
                initial={{ opacity: 0, x: 100 }}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
                transition={{ duration: 0.8 }}
                className="text-md md:text-xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
              >
                3 <span className="text-primary">&bull;</span> HR
                and Organizational Development
              </motion.h3>
            )}
          </InView>
        </div>
        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Executive recruitment.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Design and implementation of personnel procedures.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Job evaluation and grading.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Manpower planning and training.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Organization structures.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Management and control structures.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Job descriptions.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Procedures and staff manual.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Training needs assessment.</p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.h3
                ref={ref}
                initial={{ opacity: 0, x: 100 }}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
                transition={{ duration: 0.8 }}
                className="text-md md:text-xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
              >
                4 <span className="text-primary">&bull;</span> IT Advisory
                Services
              </motion.h3>
            )}
          </InView>
        </div>
        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Executive recruitment; System specification and
                        selection.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Implementation management.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">IT Policies & Procedures.</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Information Security Policies & Procedures.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Department organizational structure chart – Roles &
                        Responsibilities.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>
      </section>
    </div>
  );
}

export default BuisenessServices;
