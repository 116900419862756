import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import HeaderAction from "./TheHeader/components/HeaderAction/HeaderAction";

import { changeCompany } from "../../store/actions/globalVariables";

function SideDrawer({ isShown, toggleDrawer }) {
  const dispatch = useDispatch();

  const company = useSelector((state) => state.globalVariables.company);

  const toggleCompany = () => {
    const newCompanyName = company === "co" ? "marouf" : "co";
    dispatch(changeCompany(newCompanyName));
  };

  return (
    <aside id="side-menu">
      <div
        id="side-bar"
        className={`bg-primary w-400px h-100vh flex flex-col items-center justify-start py-20 overflow-hidden fixed top-0 left-0 bottom-0  transition-all z-50`}
        style={{
          transform: `translateX(${isShown ? "0px" : "-400px"})`,
        }}
      >
        <button
          id="close-menu"
          className="w-20 h-20 flex items-center justify-center border-none transform focus:bg-transparent hover:scale-105 transition-all"
          onClick={() => toggleDrawer(!isShown)}
        >
          <img
            src="./icons/close.svg"
            alt="close"
            className="max-w-80px mb-8"
          />
        </button>
        <HeaderAction company={company} changeCompany={toggleCompany} />
        <ul className="w-full flex flex-col items-center justify-center">
          <li className="bg-primary w-full flex items-center justify-start text-secondary-lighter text-3xl text-left py-4 pl-16 hover:bg-secondary-lighter hover:bg-opacity-25 cursor-pointer transition-all group">
            <Link
              to="/"
              className="w-full text-secondary-lighter transform group-hover:scale-105 transition-all"
            >
              Home
            </Link>
          </li>
          <li className="bg-primary w-full flex items-center justify-start text-secondary-lighter text-3xl text-left py-4 pl-16 hover:bg-secondary-lighter hover:bg-opacity-25 cursor-pointer transition-all group">
            <Link
              to="/services"
              className="w-full text-secondary-lighter transform group-hover:scale-105 transition-all"
            >
              Services
            </Link>
          </li>
          <li className="bg-primary w-full flex items-center justify-start text-secondary-lighter text-3xl text-left py-4 pl-16 hover:bg-secondary-lighter hover:bg-opacity-25 cursor-pointer transition-all group">
            <Link
              to="/blogs"
              className="w-full text-secondary-lighter transform group-hover:scale-105 transition-all"
            >
              Blogs
            </Link>
          </li>

          <li className="bg-primary w-full flex items-center justify-start text-secondary-lighter text-3xl text-left py-4 pl-16 hover:bg-secondary-lighter hover:bg-opacity-25 cursor-pointer transition-all group">
            <Link
              to="/blogs/articles"
              className="w-full text-secondary-lighter transform group-hover:scale-105 transition-all"
            >
              Articles
            </Link>
          </li>
          <li className="bg-primary w-full flex items-center justify-start text-secondary-lighter text-3xl text-left py-4 pl-16 hover:bg-secondary-lighter hover:bg-opacity-25 cursor-pointer transition-all group">
            <Link
              to="/blogs/activities"
              className="w-full text-secondary-lighter transform group-hover:scale-105 transition-all"
            >
              Activities
            </Link>
          </li>
          <li className="bg-primary w-full flex items-center justify-start text-secondary-lighter text-3xl text-left py-4 pl-16 hover:bg-secondary-lighter hover:bg-opacity-25 cursor-pointer transition-all group">
            <Link
              to="/contact-us"
              className="w-full text-secondary-lighter transform group-hover:scale-105 transition-all"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default SideDrawer;
