import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";

function TaxServices() {
  const company = useSelector((state) => state.globalVariables.company);

  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="xl:px-6 md:px-0 xl:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Tax & Payroll Advisory Services
                </motion.h2>
                <motion.h4 className="mb-2 md:mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide">
                  We offer a full range of tax specialist advice to ensure every
                  aspect of your tax position is carefully managed.
                </motion.h4>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden flex justify-center items-center xl:block xl:float-right mb-12 xl:mb-0">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
            className="w-full"
          />
        </div>
        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                key="marouf"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Nobani & Co. offers a full range of specialist tax advice to
                  ensure every aspect of your tax position is carefully managed.
                  You will be guaranteed access to our senior tax experts with
                  both technical excellence and broad commercial experience. We
                  develop solutions that will suit your business.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>
        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  1 <span className="text-primary">&bull;</span> Sales Tax
                  (Value Added Tax – Vat)
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      Sales Tax is a tax levied on final consumption of certain
                      goods & services and it is an essential part of our
                      day-to-day business affairs. At Nobani & Co., we recognize
                      that for many businesses, Sales Tax is not a simple to
                      understand. We are aware of the difficulties that
                      businesses are likely to face, the solutions that are
                      available, and we can help identify the best opportunities
                      for maximizing cash flow and Sales Tax saving.
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-md md:text-xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  1 <span className="text-primary">&bull;</span> 1 Sales Tax
                  Advisory
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      We can help you minimize the time spent on Sales-Tax
                      affairs and offer the following services:
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Advising on Sales Tax implications of specific
                        transaction and evaluating alternatives.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Assisting in all compliance issues.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Sales Tax registrations and consultation.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Advising on all aspects of Sales Tax legislation
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.h3
                ref={ref}
                initial={{ opacity: 0, x: 100 }}
                animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
                transition={{ duration: 0.8 }}
                className="text-md md:text-xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
              >
                1 <span className="text-primary">&bull;</span> 2 Sales Tax
                Review
              </motion.h3>
            )}
          </InView>
        </div>
        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Providing a long-term relationship with our clients.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Providing improved Sales Tax Management.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  2 <span className="text-primary">&bull;</span> Corporate
                  Income Tax
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      All businesses, large or small are faced with the issue of
                      operational taxes. The amount of tax you pay is highly
                      influenced by your tax planning strategies. At Nobani &
                      Co. we are familiar with several techniques to increase
                      your bottom-line while lowering your payout of capital
                      taxes, payroll taxes and taxes on investments. You can
                      rely on us for effective tax planning.
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  3 <span className="text-primary">&bull;</span> Payroll
                  Services
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      Processing payroll can be a complicated and time-consuming
                      task; hence outsourcing payroll services can help you save
                      time while making the process simpler and easier for you.
                      Payroll functions may include anything from determining
                      employee wages and withholding taxes to deducting
                      employee-contributed payments for benefits.
                    </motion.p>
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose mt-6"
                    >
                      Outsourcing your payroll services comes with numerous
                      benefits:
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold ">Convenience:</h3>
                    </div>
                    <p className="pl-6">
                      You simply need to provide the list of all employees with
                      the essential details to process their payroll. The
                      information is processed, and funds are transferred from
                      the employer's bank account to each employee's accounts.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">Confidentiality:</h3>
                    </div>
                    <p className="pl-6">
                      You can protect employees' salaries and benefits from
                      unofficial circulation and minimize your headache.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Accuracy of information
                        <span className="text-primary"> &#38;</span>
                        Compliance:
                      </h3>
                    </div>
                    <p className="pl-6">
                      You can count on us to stay up to date with emerging
                      employee's tax laws and we will withhold and deposit funds
                      in the tax department and social security department,
                      report & do all regulatory filings. We maintain all
                      records and keep you updated with relevant information.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Accuracy of information
                        <span className="text-primary"> &#38;</span>
                        Compliance:
                      </h3>
                    </div>
                    <p className="pl-6">
                      You can count on us to stay up to date with emerging
                      employee's tax laws and we will withhold and deposit funds
                      in the tax department and social security department,
                      report & do all regulatory filings. We maintain all
                      records and keep you updated with relevant information.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Managing Currency exchange issues:
                      </h3>
                    </div>
                    <p className="pl-6">
                      We assist our clients where the payment is required to be
                      made in different currencies, for example, total salary
                      may be in one currency and net pay delivery may be in
                      other currency.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">High – Tech Tools: </h3>
                    </div>
                    <p className="pl-6">
                      We process payroll using advanced technology, such as
                      electronic submission of some local forms, thus making the
                      process faster.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">Security: </h3>
                    </div>
                    <p className="pl-6">
                      We ensure the confidentiality of the information shared.
                      We maintain this by using secured data transmission
                      methods to/from the client.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">Handling Queries: </h3>
                    </div>
                    <p className="pl-6">
                      We act as a one-point contact for queries raised by the
                      client or their employees.
                    </p>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-md md:text-xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  3 <span className="text-primary">&bull;</span> 1 Payroll Tax
                  Services
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      Our payroll and tax filing services help you to manage
                      cash flow by collecting and paying your tax obligations
                      each pay period. We help you in:
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>
        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Calculation of employer income taxes.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Payment of all employer and employee tax obligations.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Complete records of all tax filing.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Protection from payroll tax penalties.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Full compliance with all local tax regulation and
                        filling requirements.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  4 <span className="text-primary">&bull;</span> International
                  Tax
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      For companies that embark on international business, tax
                      laws can get problematic. At Nobani & Co., we have access
                      to a network of tax professionals from around the world
                      who have the expertise and patience to help you get
                      through the ins and outs of tax effective earnings
                      repatriation, tax effective cross-border transactions,
                      structuring and more.
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  5 <span className="text-primary">&bull;</span> Social Security
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      At Nobani & Co. we can help you unload the monthly burden
                      of employee social security reporting. Our team has the
                      much sort after knowledge and experience in implementing
                      the local Social Security Law, including:
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Social Security Registration and consultation.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Filling Social Security forms for new current and
                        discharged employee.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Reporting for monthly Social Security deductions.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Filling for new Social Security numbers for new
                        employee.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Informing employers of changes in Social Security
                        regulations that should be taken into consideration.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  6 <span className="text-primary">&bull;</span> Transfer
                  Pricing
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      Transfer Pricing (TP) describes all aspects of
                      international inter-company pricing arrangements between
                      related entities. They encompass tangible goods,
                      intangible services, and cost sharing arrangements. We
                      assist you in:
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Operating structure review and functional analyses.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Transfer pricing documentation and planning.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Dispute resolutions with tax authorities.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">Advance Pricing Agreements.</p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  7 <span className="text-primary">&bull;</span> Mergers and
                  Acquisitions
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      Nobani & Co. can help sellers and buyers of a corporate
                      business:
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-8 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Determine whether the acquisition should be structured
                        as a taxable or nontaxable transaction.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        If nontaxable, determine the type of tax-free
                        transaction that should be used to accomplish the
                        parties' individual goals.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        If taxable, decide whether the transaction should be
                        structured as a stock or asset acquisition.
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <p className="text-base">
                        Maximize the use of any net operating losses and other
                        tax attributes of the business being acquired.
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>
      </section>
    </div>
  );
}

export default TaxServices;
