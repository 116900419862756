import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import TheHeader from "../components/organisms/TheHeader/TheHeader";
import TheFooter from "../components/organisms/TheFooter";
import Modal from "../components/molecules/Modal";

const successTitle = "Success!";
const errorTitle = "Oops!";
const successText = "Your information has been sent successfully!";

function ContactUs() {
  const [file, setFile] = useState(null);
  const [fullName, setFullName] = useState("");
  const [jobType, setJobType] = useState("");
  const [email, setEmail] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const submit = async (e) => {
    e.preventDefault();
    if (!file || !fullName || !jobType || !file) return;
    console.log(file);
    if (!file) return;
    const body = {
      full_name: fullName,
      job_type: jobType,
      email: email,
    };
    const textData = JSON.stringify(body);
    const reqData = new FormData();

    reqData.append("files.resume", file, file.name);
    reqData.append("data", textData);

    for (var pair of reqData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    console.log(reqData);
    try {
      setIsPending(true);
      await axios.post("https://api.nobani.com/api/hirings", reqData);
      setIsPending(false);
      setIsSuccess(true);
      setIsError(false);
      setModalTitle(successTitle);
      setModalText(successText);
      setFile(null);
      setFullName("");
      setJobType("");
      setEmail("");
      open();
    } catch (err) {
      const errorData = err.response.data.error;
      console.log("err", err.response);
      setIsPending(false);
      setIsSuccess(false);
      setIsError(true);
      setModalTitle(errorTitle);
      setModalText(`${errorData.name}: ${errorData.message}`);
      open();
    }
  };

  return (
    <>
      <TheHeader />
      <section className="mt-40">
        <div className="flex flex-col lg:grid lg:grid-cols-2">
          <div className="relative">
            <div className="absolute right-4vw top-12 border-b-2">
              <h1 className="text-4xl lg:text-6xl font-bold text-primary">
                Get in touch
              </h1>
            </div>
            <img
              src="./illustrations/get-in-touch-animate.svg"
              alt="Get in touch"
            />
          </div>
          <div className="bg-primary h-full flex justify-center items-center pt-24 lg:pt-0">
            <form
              className="w-full flex flex-col px-6 md:px-32"
              onSubmit={submit}
            >
              <div className="flex flex-col mb-12">
                <label
                  htmlFor="fullName"
                  className="text-white text-2xl font-bold mb-2"
                >
                  Full name
                </label>
                <input
                  type="text"
                  id="fullName"
                  className="h-10 px-4 rounded outline-none"
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col mb-12">
                <label
                  htmlFor="fieldOfInterest"
                  className="text-white text-2xl font-bold mb-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="fieldOfInterest"
                  className="h-10 px-4 rounded outline-none"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col mb-12">
                <label
                  htmlFor="fieldOfInterest"
                  className="text-white text-2xl font-bold mb-2"
                >
                  Field of interest
                </label>
                <input
                  type="text"
                  id="fieldOfInterest"
                  className="h-10 px-4 rounded outline-none"
                  value={jobType}
                  onChange={(e) => {
                    setJobType(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col mb-12">
                <label
                  htmlFor=""
                  className="text-white text-2xl font-bold mb-8"
                >
                  Upload resume
                </label>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                  <motion.label
                    htmlFor="resume"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="text-primary text-md md:text-xl font-bold bg-white flex justify-center items-center px-6 md:px-12 py-2 whitespace-nowrap rounded-full cursor-pointer transform transition hover:scale-105 mb-8 md:mb-2"
                  >
                    Choose file
                  </motion.label>
                  <input
                    type="file"
                    id="resume"
                    className="hidden"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <p className="text-white text-xl md:text-2xl font-bold border-b-2">
                    {file ? file.name : "No file chosen"}
                  </p>
                </div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  disabled={isPending}
                  className="mt-16 self-end text-primary text-md md:text-xl font-bold mb-2 bg-white flex justify-center items-center px-8 md:px-12 py-2 whitespace-nowrap rounded-full cursor-pointer transform transition hover:scale-105"
                >
                  Send
                </motion.button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <TheFooter />
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {modalOpen && (
          <Modal
            modalOpen={modalOpen}
            isSuccess={isSuccess}
            isError={isError}
            title={modalTitle}
            text={modalText}
            handleClose={close}
          />
        )}
      </AnimatePresence>
    </>
  );
}

export default ContactUs;
