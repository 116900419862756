export const changeTheme = (themeStyle) => {
  return (dispatch) => {
    dispatch({ type: "theme", data: themeStyle });
  };
};

export const changeCompany = (companyName) => {
  return (dispatch) => {
    dispatch({ type: "company", data: companyName });
  };
};
