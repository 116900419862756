import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";

function Testimonials() {
  const [state, setState] = useState(0);
  const testimonials = [
    {
      id: "1",
      imgUrl: "https://i.ibb.co/6WypSVj/Picture2.png",
      text: `Nobani & Co specialises in audit, tax, payroll, secretarial and business advisory services (including due diligence and merger support) for clients in the service, manufacturing, trading, IT, telecommunication, health, hotels & entertainment, real estate, education and NGO sectors. 
      `,
      name: "",
      job: "",
      company: "Praxity",
    },
    {
      id: "2",
      imgUrl: "https://i.ibb.co/YcptSNK/Picture1.png",
      text: `Nobani & Co. team have been wonderful, you have all gone
      beyond the call of duty and are a credit to the accounting
      profession. It would also be my honor to recommend Nobani
      & Co. in Jordan to anyone who is considering using your
      services and would like to hear from your existing clients
      before making their commitment.`,
      name: "Jaafar Malik",
      job: "Managing Partner",
      company: "SHUKR Islamic Clothing",
    },
    {
      id: "3",
      imgUrl: "https://i.ibb.co/9Y7F58t/Picture3.png",
      text: `Mr.Nobani and Ms.Faten - Just a short note to thank you for all your efforts over the past few weeks on the Fosroc Amman and Aqaba tax compliance project. I greatly appreciate the extra time that you’ve put into the project and feel that it could not have been successful without your involvement. Please accept my personal gratitude.`,
      name: "Nitin",
      company: "Fosroc Amman",
    },
    {
      id: "4",
      imgUrl: "https://i.ibb.co/j8TS27f/cooperaction.png",
      text: `This is to certify that Nobani & Co has fully conducted the audit service of our project Qudra during the period of August-December 2019.
      Nobani & Co. carried out kick off meetings and submitted work plans for the abovementioned service. They also performed the audit activities as per the Terms of Reference. 
      `,
      name: "",
      company: "Cooperacion Espanola",
    },
    {
      id: "5",
      imgUrl: "https://i.ibb.co/7KC2dJZ/cloudtesti.png",
      text: `Al-Nobani firm genuinely is one of the best I have ever worked with, and I thoroughly enjoyed working with you.
      You did five-star work, you were ultra-responsive, and you made things easy for us. That is invaluable.
      `,
      name: "",
      company: "care",
    },
  ];
  return (
    <>
      <section className="z-10 md:mb-32">
        <div className="relative lg:max-w-3/4 mx-auto flex flex-col items-center justify-center px-12 py-8">
          <div className="w-1/4 h-1/2 absolute left-0 top-0 border-t-8 border-l-8 border-primary" />
          <AnimatePresence exitBeforeEnter>
            {testimonials.map(
              (testimonial, idx) =>
                idx === state && (
                  <motion.div
                    className="relative h-150px flex items-center"
                    key={`img${testimonial.id}`}
                    initial={{ opacity: 0.5 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0.5 }}
                    transition={{ duration: 0.4 }}
                  >
                    <img
                      src={testimonial.imgUrl}
                      alt="placeholder"
                      className="max-w-150px mb-8 z-10"
                    />
                    <img
                      src="./icons/cutestar-1.png"
                      alt="cutestar-1"
                      className="opacity-50 absolute top-0 -left-4 -z-10 transform scale-50"
                    />
                    <img
                      src="./icons/cutestar-2.png"
                      alt="cutestar-2"
                      className="opacity-50 absolute top-1/3 -right-8 -z-10 transform scale-75"
                    />
                    <img
                      src="./icons/cutestar-3.png"
                      alt="cutestar-3"
                      className="opacity-80 absolute bottom-2 -z-10 transform scale-75"
                    />
                  </motion.div>
                )
            )}
          </AnimatePresence>

          <p className="text-center font-medium italic my-2 flex items-center text-lg">
            <img
              src="./icons/quote.svg"
              alt="quote"
              className=" w-6 h-6 self-start mr-2"
            />
            <AnimatePresence exitBeforeEnter>
              {testimonials.map(
                (testimonial, idx) =>
                  idx === state && (
                    <motion.span
                      key={`span${testimonial.id}`}
                      initial={{ opacity: 0.5 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0.5 }}
                      transition={{ duration: 0.4 }}
                    >
                      {testimonial.text}
                    </motion.span>
                  )
              )}
            </AnimatePresence>
            <img
              src="./icons/quote.svg"
              alt="quote"
              className="w-6 h-6 transform rotate-180 self-end ml-2"
            />
          </p>
          <AnimatePresence exitBeforeEnter>
            {testimonials.map(
              (testimonial, idx) =>
                idx === state && (
                  <motion.div
                    className="flex flex-col items-center justify-center my-4"
                    key={`info${testimonial.id}`}
                    initial={{ opacity: 0.5 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0.5 }}
                    transition={{ duration: 0.4 }}
                  >
                    <h3 className="font-bold text-xl tracking-wider mb-2">
                      {testimonial.name}
                    </h3>
                    <h4 className="text-sm font-semibold text-primary-dark">
                      {testimonial.job}
                    </h4>
                    <h5 className="text-xs font-semibold text-primary">
                      {testimonial.company}
                    </h5>
                  </motion.div>
                )
            )}
          </AnimatePresence>
          <div className="flex">
            {testimonials.map((testimonial, idx) => (
              <button
                className={`mx-1 mt-4 border-2 border-primary px-2 ${
                  idx !== state && "opacity-30"
                }`}
                onClick={() => setState(idx)}
                key={idx}
              ></button>
            ))}
          </div>
          <div className=" w-2/12 h-1/3 absolute right-0 bottom-0 border-b-8 border-r-8 border-primary" />
        </div>
      </section>
    </>
  );
}

export default Testimonials;
