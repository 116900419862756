import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

function SeasonEnd() {
  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
                className="mb-24"
              >
                <motion.h2 className="mb-8 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  End of Season Outing
                </motion.h2>

                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  At the end of each audit season, Nobani & Co and Nobani &
                  Marouf host an outing as an appreciation of the hard work done
                  by the employees. Every season has a different theme to our
                  employees entertained and forget the work stress for a day!
                </motion.p>
              </motion.div>
            )}
          </InView>
          <Carousel swipeable>
            <div>
              <img src="https://i.ibb.co/YZW6xnG/end-of-seasonn.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/PZN6g5T/63610583681303986923.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/Fb1yS15/63572042053918347142.jpg" />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default SeasonEnd;
