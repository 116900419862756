import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function FinancialServices() {
  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="xl:px-6 md:px-0 xl:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Financial Advisory Services
                </motion.h2>
                <motion.h4 className="mb-2 md:mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide">
                  We bring in world class expertise developed and honed over
                  years of experience in various markets and industries.
                </motion.h4>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden flex justify-center items-center xl:block xl:float-right mb-12 xl:mb-0">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
            className="w-full"
          />
        </div>
        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                key="marouf"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Our clients can select one or more of our services, where we
                  work with them to deliver seamless customized integrated
                  solutions that help them sharpen their respective competitive
                  advantage and ensure their continued success.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  1 <span className="text-primary">&bull;</span> 1 Field of
                  specialty
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.ul key="marouf" className="pl-8 mt-8">
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Corporate Finance</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          Due diligence and Investigations
                        </p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Business Valuation</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Mergers and Acquisition</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Transaction Services</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          Financial Modelling & Budgeting
                        </p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          International Public Sector Accounting Standards
                          “IPSAS” adoption
                        </p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">IFRS adoption</p>
                      </div>
                    </motion.li>
                  </motion.ul>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h4
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-xl font-medium mb-4 pb-2 max-w-min whitespace-nowrap"
                >
                  We also provide special training in:
                </motion.h4>
                <AnimatePresence exitBeforeEnter>
                  <motion.ul key="marouf" className="pl-8 mt-8">
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">IFRS Accreditation</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          Quality Control (Peer Reviewers)
                        </p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Internal & External Audit</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">Enterprise Risk management</p>
                      </div>
                    </motion.li>
                    <motion.li
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                    >
                      <div className="flex items-start justify-start mb-2">
                        <img
                          src="./icons/bullet.svg"
                          alt="bullet-point"
                          className="w-4 h-4 mr-2 mt-1"
                        />
                        <p className="text-base">
                          Corporate Governance and compliance
                        </p>
                      </div>
                    </motion.li>
                  </motion.ul>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>
      </section>
    </div>
  );
}

export default FinancialServices;
