import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

function EzraBaladak() {
  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
                className="mb-24"
              >
                <motion.h2 className="mb-8 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Ezra' Baladak Campaign
                </motion.h2>

                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  At Nobani & Co and Nobani & Marouf, we aim to enhance our
                  community in various ways.
                </motion.p>
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Ezra' Baladak Campaign is a campaign managed by nahnojo
                  organisation to plant trees in Dibeen Forest that suffered
                  from severe fires. And we also showed gratitude to
                  almawa.jordan for creating a suitable atmosphere and a home
                  for tortured animals.
                </motion.p>
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Planting a life creates a life, and we hope we all be socially
                  responsible to build a better Jordan.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <Carousel swipeable>
            <div>
              <img src="https://i.ibb.co/Q6XLL50/5fc3ce0e-9bf6-4b1c-b437-1a44c8e65a8f.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/X4471mW/6e625adf-9cac-457b-87e6-a64b0bdfcaa5.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/VVgLpKL/65bd5550-12c9-47b8-bc55-63ae266733b4.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/dGC85FG/e383adaf-fb8b-460c-8e11-ffcc5c3172b3.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/DG525Jk/eb1a455a-f350-4b6a-a061-ece837865f63.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/d6QkHwC/PHOTO-2021-10-14-18-53-39.jpg" />
            </div>
            <div>
              <img src="https://i.ibb.co/17QhXzF/PHOTO-2021-10-14-19-46-58.jpg" />
            </div>
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default EzraBaladak;
