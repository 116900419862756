import React from "react";
import { Route } from "react-router";

import BlogCard from "../../../components/molecules/BlogCard";

function BlogsList() {
  return (
    <section className="w-full md:px-16 lg:w-8/12 flex flex-col items-center justify-start gap-12 mb-40 mt-14 md:mt-28">
      <Route exact path={["/blogs", "/blogs/articles"]}>
        <BlogCard
          imageUrl="https://i.ibb.co/h2gc8rW/pexels-fauxels-3184292.jpg"
          date="7th November, 2021"
          title="Business Transition"
          description="Whether you are a small, family-owned business or a large private
            company with professional management, the transitioning of your
            business through the course of your stewardship is critical."
          link="/blogs/articles/business-transition"
          classes=""
        />
        <BlogCard
          imageUrl="https://i.ibb.co/dPxvPYh/pexels-pixabay-53621.jpg"
          date="7th November, 2021"
          title="Financing"
          description="Regardless of the size and scale of our clients, the single most important and frequent issue that arises is managing their financing. This issue extends beyond the raising of capital and managing banker/lender/financier relationships and into working capital management and capital structure."
          link="/blogs/articles/financing"
          classes=""
        />
        <BlogCard
          imageUrl="https://i.ibb.co/t8GnhQm/pexels-singkham-1108572.jpg"
          date="7th November, 2021"
          title="Growth"
          description="When we talk to clients about growth, we hear a wide variety of reasoning and rationale, but the decision to focus on growth should ultimately be treated like any other business decision. An informed and thoughtful approach to growth will produce a far better result than an impulse decision."
          classes=""
          link="/blogs/articles/growth"
        />
        <BlogCard
          imageUrl="https://i.ibb.co/k9mY6Y4/pexels-alphatradezone-5833304.jpg"
          date="7th November, 2021"
          title="Risk Management"
          description="More than ever, our clients are looking to manage their risks. They are specifically emphasizing the new and emerging challenges, economic and regulatory realities. As our clients explore new markets, experience more regulations, and enter complex financial transactions, they endure an increased "
          link="/blogs/articles/risk-management"
          classes=""
        />
        <BlogCard
          imageUrl="https://i.ibb.co/cCV4nPD/pexels-wendy-wei-1677710.jpg"
          date="7th November, 2021"
          title="Talent"
          description="The ongoing success of your business is dependent on the strength of your people, and your ability to channel their talent. In fact, concerns around the capabilities and competencies of staff represent some of the key business issues that arise in our conversations with clients."
          link="/blogs/articles/talent"
          classes=""
        />
        <BlogCard
          imageUrl="https://i.ibb.co/H73Mxyb/pexels-nataliya-vaitkevich-6863183.jpg"
          date="7th November, 2021"
          title="Tax Optimization"
          description="Although all business owners want to minimize the tax that they pay, not everyone can - or wants to - spend the time and energy to invest in researching and structuring their business affairs to optimize their tax obligations."
          link="/blogs/articles/tax-optimization"
          classes=""
        />
      </Route>
      <Route exact path={["/blogs", "/blogs/activities"]}>
        <BlogCard
          imageUrl="https://i.ibb.co/9qTw2FF/nobani-posts-07.jpg"
          date="7th November, 2021"
          title="Breast Cancer Awarenewss"
          description="To honor the fighters who are suffering, we at Nobani&co 
          and Nobani&Marouf hosted a breast cancer awareness campaign. it included a presentation on facts (should know), importance, prevention methods and uniting our stand as one to support the patients by donations and other supporting activities.
          "
          link="/blogs/activities/cancer-awareness"
          classes=""
        />
        <BlogCard
          imageUrl="https://i.ibb.co/cJBGqGt/758bae67-544f-4cbe-94d4-58c0c336a1a2.jpg"
          date="7th November, 2021"
          title="End Of Season Outing"
          description="At the end of each audit season, Nobani & Co and Nobani & Marouf host an outing as an appreciation of the hard work done by the employees. Every season has a different theme to our employees entertained and forget the work stress for a day! "
          classes=""
          link="/blogs/activities/season-end"
        />
        <BlogCard
          imageUrl="https://i.ibb.co/1mdqz60/65bd5550-12c9-47b8-bc55-63ae266733b4.jpg"
          date="7th November, 2021"
          title="Ezra' Baladak Campaign"
          description="At Nobani & Co and Nobani & Marouf, we aim to enhance our community in various ways. 

          Ezra' Baladak is a campaign managed by nahnojo organisation to plant trees in Dibeen Forest that suffered from severe fires. And we also showed gratitude to almawa.jordan for creating a suitable atmosphere and a home for tortured animals. 
          "
          classes=""
          link="/blogs/activities/ezra-baladak"
        />
      </Route>
    </section>
  );
}

export default BlogsList;
