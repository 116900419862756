import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

import Button from "../atoms/Button";

function BlogCard({ imageUrl, date, title, description, link, classes }) {
  return (
    <InView threshold={0.5} triggerOnce>
      {({ ref, inView }) => (
        <motion.div
          className={`${classes}bg-gray-100 flex flex-col items-center justify-start p-4 shadow-xl rounded border-b-4 border-primary`}
          ref={ref}
          initial={{ opacity: 0, y: 100 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
          exit={{ opacity: 0, y: 100 }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-400px bg-bottom object-cover rounded filter brightness-75"
          />
          <div className="flex items-center justify-center px-4 transform -translate-y-6 ">
            <div className="bg-white py-6 px-4 shadow-lg flex flex-col items-start justify-center">
              <h6 className="text-xs font-bold mb-4 text-primary">{date}</h6>
              <h3 className="text-xl font-bold mb-4 border-b-4 border-primary uppercase pb-1 pr-2">
                {title}
              </h3>
              <p className=" h-50px truncate-2-lines text-base tracking-tight mb-8">
                {description}
              </p>
              <div className="w-full flex justify-end">
                <Link to={link}>
                  <Button>Read More</Button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </InView>
  );
}

export default BlogCard;
