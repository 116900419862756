import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Button from "../atoms/Button.jsx";

function TheHero() {
  const theme = useSelector((state) => state.globalVariables.theme);

  return (
    <>
      <section
        className={`relative w-full grid place-content-center z-10 pt-12 pb-16  md:mb-32 md:pb-24
      ${theme === "dark" ? "bg-light-gradient" : "bg-dark-gradient"} `}
      >
        <div className="container w-full pt-32 grid grid-cols-1 md:grid-cols-2 gap-12 ">
          <div className="text-primary-dark flex flex-col justify-center items-start md:items-end text-left md:text-right z-10 px-6 md:px-0">
            <h1 className="text-3xl sm:text-4xl  lg:text-5xl xl:text-6xl font-bold capitalize max-w-prose mb-12">
              Enhancing our community, <br />
              Digitally
            </h1>
            <div className="flex flex-row gap-8">
              <HashLink to="#aboutus">
                <Button classes="mt-4" isRounded>
                  About Us
                </Button>
              </HashLink>
              <Link to="/services">
                <Button classes="mt-4" isRounded>
                  our services
                </Button>
              </Link>
            </div>
          </div>
          <div className="hidden md:flex items-center justify-center lg:w-11/12 xl:w-full ">
            <img src="./illustrations/business-analytics.svg" alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default TheHero;
