import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import { changeCompany } from "../../store/actions/globalVariables";

function Mission() {
  const company = useSelector((state) => state.globalVariables.company);
  const dispatch = useDispatch();
  const toggleCompany = () => {
    const newCompanyName = company === "co" ? "marouf" : "co";
    dispatch(changeCompany(newCompanyName));
  };
  const NobaniMarouf = () => {
    return (
      <h4 className=" text-sm md:text-xl text-primary-dark font-bold max-w-screen-lg leading-normal">
        Since day one, Nobani and Marouf had a goal to provide the best services
        in any condition. <br /> <br /> We work every day to ensure our world is
        safe for businesses and it is corruption-free. <br /> <br /> Our space
        is friendly, motivated, up to learning and taking new risks, we never
        fail to attain our hardship. <br /> <br /> Our services are unique, and
        our growth is a proof.{" "}
      </h4>
    );
  };
  const NobaniCo = () => {
    return (
      <h4 className=" text-sm md:text-xl text-primary-dark font-bold max-w-screen-lg leading-normal">
        Our mission is to give our nation a safe business world by ensuring that
        fraud is eliminated. <br /> <br /> To us, this is more than just a job,
        it is a necessity we thrive to continuously accomplish. <br /> <br /> A
        clean statement is an honest credential presented to the world.
      </h4>
    );
  };
  return (
    <section className="relative w-full flex justify-center items-center mb-24 px-6 lg:px-16">
      <div className="opacity-65 absolute bottom-0 -left-1/2 -z-20 md:left-0 md:-bottom-1/4 lg:-bottom-1/2 lg:right-0 xl:inset-0">
        <img
          src="./illustrations/blob-scene2.svg"
          alt=""
          className="w-full transform rotate-90 md:rotate-0"
        />
      </div>
      <div className="container grid grid-cols-1 md:grid-cols-2 md:py-16 px-6 md:px-0">
        <div className="flex flex-col justify-start">
          <div className="mb-8">
            <InView threshold={0.7} triggerOnce>
              {({ ref, inView }) => (
                <motion.h2
                  ref={ref}
                  initial={{ opacity: 0, y: -50 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }
                  }
                  transition={{ duration: 0.8 }}
                  className="capitalize text-primary text-2xl md:text-4xl font-bold tracking-tighter md:mb-8"
                >
                  Our Mission
                </motion.h2>
              )}
            </InView>

            {/* <InView threshold={0.6} triggerOnce>
              {({ ref, inView }) => (
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="flex font-medium rounded-full overflow-hidden max-w-min "
                >
                  <li
                    onClick={toggleCompany}
                    className={`${
                      company === "co"
                        ? "text-secondary-lighter bg-primary font-bold"
                        : "bg-secondary-light"
                    } cursor-pointer text-sm md:text-base hover:text-secondary-lighter hover:bg-primary bg-opacity-70 p-2 px-3 md:p-4 transition whitespace-nowrap`}
                  >
                    Nobani & Co
                  </li>
                  <li
                    onClick={toggleCompany}
                    className={`${
                      company === "marouf"
                        ? "text-secondary-lighter bg-primary font-bold"
                        : "bg-secondary-light"
                    } cursor-pointer text-sm md:text-base hover:text-secondary-lighter hover:bg-primary bg-opacity-70 p-2 px-3 md:p-4 transition whitespace-nowrap`}
                  >
                    Nobani & Marouf
                  </li>
                </motion.ul>
              )}
            </InView> */}
          </div>

          <InView threshold={0.5} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                {company === "co" ? (
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="co"
                  >
                    <NobaniCo />
                  </motion.div>
                ) : company === "marouf" ? (
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <NobaniMarouf />
                  </motion.div>
                ) : null}
              </AnimatePresence>
            )}
          </InView>
        </div>
        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 100 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }}
              transition={{ duration: 1 }}
              className="w-full hidden md:flex justify-center items-center"
            >
              <img
                src="./illustrations/launching-amico.svg"
                alt=""
                className=" w-3/4"
              />
            </motion.div>
          )}
        </InView>
      </div>
    </section>
  );
}

export default Mission;
