import React from "react";
import { Route, Switch, Link } from "react-router-dom";

import TheFooter from "../../components/organisms/TheFooter";
import TheHeader from "../../components/organisms/TheHeader/TheHeader";
import BlogsList from "./subpages/BlogsList";
import BuisinessTransition from "./subpages/BuisinessTransition";
import Financing from "./subpages/Financing";
import Growth from "./subpages/Growth";
import RiskManagement from "./subpages/RiskManagement";
import Talent from "./subpages/Talent";
import TaxOptimization from "./subpages/TaxOptimization";
import CancerAwareness from "./subpages/CancerAwareness";
import SeasonEnd from "./subpages/SeasonEnd";
import EzraBaladak from "./subpages/EzraBaladak";

function Blogs() {
  return (
    <div className="w-full grid place-items-center place-content-center">
      <TheHeader classes="bg-secondary-lighter text-white" />
      <section className="mt-32 md:mt-40">
        <div className="nav">
          <Link to="/blogs" className="link">
            <div className="prev">
              <i className="fas fa-blog"></i>
            </div>
            <div className="small">
              <i className="fas fa-blog"></i>
            </div>
            <div className="full">
              <div className="f1">All Blogs</div>
              <div className="f2">View all blogs </div>
            </div>
          </Link>
          <Link to="/blogs/articles" className="link">
            <div className="prev">
              <i className="far fa-newspaper"></i>
            </div>
            <div className="small">
              <i className="far fa-newspaper"></i>
            </div>
            <div className="full">
              <div className="f1">Articles</div>
              <div className="f2">View articles </div>
            </div>
          </Link>
          <Link to="/blogs/activities" className="link">
            <div className="prev">
              <i className="fas fa-hiking"></i>
            </div>
            <div className="small">
              <i className="fas fa-hiking"></i>
            </div>
            <div className="full">
              <div className="f1">Activities</div>
              <div className="f2">View activities</div>
            </div>
          </Link>
        </div>
      </section>
      <Switch>
        <Route
          path={["/blogs", "/blogs/articles", "/blogs/activities"]}
          exact
          component={BlogsList}
        />
        <Route
          path="/blogs/articles/business-transition"
          exact
          component={BuisinessTransition}
        />
        <Route path="/blogs/articles/financing" exact component={Financing} />
        <Route path="/blogs/articles/growth" exact component={Growth} />
        <Route
          path="/blogs/articles/risk-management"
          exact
          component={RiskManagement}
        />
        <Route path="/blogs/articles/talent" exact component={Talent} />
        <Route
          path="/blogs/articles/tax-optimization"
          exact
          component={TaxOptimization}
        />
        <Route
          path="/blogs/activities/cancer-awareness"
          exact
          component={CancerAwareness}
        />
        <Route
          path="/blogs/activities/season-end"
          exact
          component={SeasonEnd}
        />
        <Route
          path="/blogs/activities/ezra-baladak"
          exact
          component={EzraBaladak}
        />
      </Switch>
      <TheFooter />
    </div>
  );
}

export default Blogs;
