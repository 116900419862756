import { motion, AnimatePresence } from "framer-motion";
import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { changeCompany } from "../../../store/actions/globalVariables";

import HeaderAction from "./components/HeaderAction/HeaderAction";
import SideDrawer from "../SideDrawer";

const slide = {
  hidden: {
    x: "-340px",
    opacity: 0,
  },
  visible: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.6,
      type: "tween",
    },
  },
  exit: {
    x: "-340px",
    opacity: 0,
  },
};

function TheHeader({ classes }) {
  const [shown, setShown] = useState(false);
  const [goingDown, setGoingDown] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector((state) => state.globalVariables.company);

  const toggleCompany = () => {
    const newCompanyName = company === "co" ? "marouf" : "co";
    dispatch(changeCompany(newCompanyName));
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY <= 200 && goingDown) {
        setGoingDown(false);
        console.log(goingDown, currentScrollY);
      } else if (currentScrollY > 200 && !goingDown) {
        setGoingDown(true);
        console.log(goingDown, currentScrollY);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingDown]);
  return (
    <>
      <SideDrawer isShown={shown} toggleDrawer={setShown} />

      <section
        className={`w-full ${
          goingDown
            ? "h-16 md:h-20 bg-secondary-lighter shadow-lg"
            : "h-32 md:h-40"
        }  flex flex-row items-center justify-center fixed top-0 z-40 transition-all duration-500 ${classes}`}
      >
        <nav
          className={`w-full flex justify-between items-center ${
            goingDown ? "pl-6 lg:px-0" : "pl-6 md:px-16"
          } lg:px-24`}
        >
          <div
            className="md:hidden flex items-center justify-center"
            onClick={() => setShown(!shown)}
          >
            <img src="./icons/ham-menu.svg" width="40" height="40" alt="" />
          </div>
          <AnimatePresence exitBeforeEnter>
            {company === "co" && (
              <motion.div
                className="max-w-xxs flex items-center"
                variants={slide}
                initial="hidden"
                animate="visible"
                exit="exit"
                key="co"
              >
                <img
                  src="./logo/Nobani-logo.png"
                  alt="Nobani & Co logo"
                  className={`min-w-200px w-11/12 max-w-200px md:w-full transform transition-all duration-500 ${
                    goingDown ? "scale-100 lg:scale-50" : "scale-100"
                  }`}
                />
              </motion.div>
            )}
            {company === "marouf" && (
              <motion.div
                className="max-w-xxs flex items-center"
                variants={slide}
                initial="hidden"
                animate="visible"
                exit="exit"
                key="marouf"
              >
                <img
                  src="./logo/NobaniMarouf-black.png"
                  alt="Nobani & Marouf logo"
                  className={`min-w-200px max-w-250px w-11/12 md:w-full transform transition-all duration-500 ${
                    goingDown ? "scale-125 lg:scale-62.5" : "scale-100"
                  }`}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <div className="hidden md:flex items-center justify-center lg:justify-end lg:gap-12">
            <ul className="grid grid-flow-col place-items-center gap-4 pl-4 lg:pl-0 lg:gap-6">
              <li
                className={`cursor-pointer text-primary-dark font-medium tracking-widest focus:outline-none transform hover:text-primary hover:scale-110 transition-all duration-500
              ${goingDown ? "text-sm" : "text-lg"}
              `}
              >
                <Link to="/">Home</Link>
              </li>
              <li
                className={`cursor-pointer text-primary-dark font-medium tracking-widest focus:outline-none transform hover:text-primary hover:scale-110 transition-all duration-500
              ${goingDown ? "text-sm" : "text-lg"}
              `}
              >
                <Link to="/services">Services</Link>
              </li>
              {/* <li
              className={`cursor-pointer text-primary-dark font-medium tracking-widest focus:outline-none transform hover:text-primary hover:scale-110 transition-all duration-500
              ${goingDown ? "text-sm" : "text-lg"}
              `}
            >
              <Link to="#">Case</Link>
            </li> */}
              <li
                className={`cursor-pointer text-primary-dark font-medium tracking-widest focus:outline-none transform hover:text-primary hover:scale-110 transition-all duration-500
              ${goingDown ? "text-sm" : "text-lg"}
              `}
              >
                <Link to="/blogs">Blogs</Link>
              </li>
              <li
                className={`cursor-pointer text-primary-dark font-medium tracking-widest focus:outline-none transform hover:text-primary hover:scale-110 transition-all duration-500
              ${goingDown ? "text-sm" : "text-lg"}
              `}
              >
                <Link to="/contact-us">Contact</Link>
              </li>
            </ul>
            <div className="flex flex-col justify-center items-center">
              <HeaderAction
                company={company}
                changeCompany={toggleCompany}
                classes={`${
                  goingDown
                    ? "transform scale-75 transition-all duration-500"
                    : "transition-all duration-500"
                } `}
              />
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}

export default TheHeader;
