import React from "react";

import TheHeader from "../components/organisms/TheHeader/TheHeader";
import TheHero from "../components/organisms/TheHero";
import CardGrid from "../components/organisms/CardGrid";
import Mission from "../components/organisms/Mission";
import AboutUs from "../components/organisms/AboutUs";
import PartnersLetter from "../components/organisms/PartnersLetter";
import Vision from "../components/organisms/Vision";
import TheFooter from "../components/organisms/TheFooter";
import ContactUs from "../components/organisms/ContactUs";
import Testimonials from "../components/organisms/Testimonials";

function Home() {
  return (
    <div className="">
      <TheHeader />
      <TheHero />
      <AboutUs />
      <CardGrid />
      <Mission />
      <PartnersLetter />
      <Vision />
      {/* <ContactUs /> */}
      <Testimonials />
      <TheFooter />
    </div>
  );
}

export default Home;
