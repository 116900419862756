import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function AssuranceServices() {
  return (
    <div className="w-full grid place-items-center">
      <section className="container py-24 px-6">
        <div className="xl:px-6 md:px-0 xl:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Assurance Services
                </motion.h2>
                <motion.h4 className="mb-2 md:mb-8 text-primary-dark text-lg md:text-2xl font-bold tracking-wide">
                  We provide distinctively unique audit solutions for our
                  clients.
                </motion.h4>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden flex justify-center items-center xl:block xl:float-right mb-12 xl:mb-0">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
            className="w-full"
          />
        </div>
        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                key="marouf"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  We have competent and qualified audit teams led by our
                  partners who have vast experience in delivering personalized
                  and customized solutions. Our audit planning and strategy is
                  business-risk oriented and therefore, tailored for each of our
                  clients to reflect the specific operational, regulatory, and
                  financial risks they face.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-16">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial={{ opacity: 0, x: 100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-lg md:text-2xl font-medium mb-4 pb-2 border-b-4 border-primary border-opacity-50 max-w-min whitespace-nowrap"
                >
                  1 <span className="text-primary">&bull;</span> Statutory Audit
                  Services
                </motion.h3>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                      inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                    }
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.6 }}
                    key="marouf"
                  >
                    <motion.p
                      ref={ref}
                      initial={{ opacity: 0, y: 100 }}
                      animate={
                        inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                      }
                      transition={{ duration: 0.8 }}
                      className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                    >
                      It is a fundamental need for companies to be audited under
                      the International Standards of Auditing (ISA), with
                      accounts being prepared under International Financial
                      Reporting Standards (IFRS). We, at Nobani & Marouf.,
                      specialize in fulfilling this obligation of most companies
                      regardless of their size or industry. We also work with
                      multi-national groups. We have talented, experienced, and
                      professional personnel who work closely with our clients
                      to ensure their statutory obligations are met and their
                      audited accounts are filed on time. We keep our clients
                      informed of any international accounting developments and
                      have access to state-of-the-art auditing methodology in
                      carrying our audit effectively and efficiently.
                    </motion.p>
                  </motion.div>
                </AnimatePresence>
              </>
            )}
          </InView>
        </div>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul key="marouf">
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold ">
                        Nobani <span className="text-primary"> &#38;</span>{" "}
                        Marouf. Methodology :
                      </h3>
                    </div>
                    <p className="pl-6">
                      Our risk-based audit approach focuses on business-drives,
                      associated risks, and the potential effects on the
                      financial statements.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Audit of financial statements :
                      </h3>
                    </div>
                    <p className="pl-6">
                      We focus on your financial statements and related
                      accounting records, then we carry out tests to verify your
                      statistics. We examine the reasons for your account
                      fluctuations. We help to discover areas of improvement,
                      alerts that may be of concern before they become surprises
                      and aid you to confidently move your business forward.
                    </p>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-10 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-center justify-start mb-2">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2"
                      />
                      <h3 className="font-bold">
                        Review of financial statements:
                      </h3>
                    </div>
                    <p className="pl-6">
                      A review consists primarily of enquiry, analytical
                      procedures, and discussions related to information
                      provided to evaluate whether the information provided is
                      credible.
                    </p>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>
      </section>
    </div>
  );
}

export default AssuranceServices;
