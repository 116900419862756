import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { InView } from "react-intersection-observer";

function Financing() {
  return (
    <div className="w-full grid place-items-center mt-40">
      <section className="container py-24 px-6">
        <div className="px-6 md:px-0 md:mb-24">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                transition={{ duration: 0.8, staggerChildren: 3 }}
              >
                <motion.h2 className="mb-4 capitalize text-primary text-2xl md:text-4xl font-bold tracking-normal text-left">
                  Financing
                </motion.h2>
              </motion.div>
            )}
          </InView>
        </div>
        <div className="rounded-2xl overflow-hidden float-right">
          <img
            src="https://i.ibb.co/gjbnYgk/pexels-sora-shimazaki-5673488-1.jpg"
            alt="Assurance"
          />
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Regardless of the size and scale of our clients, the single
                  most important and frequent issue that arises is managing
                  their financing. This issue extends beyond the raising of
                  capital and managing banker/lender/financier relationships and
                  into working capital management and capital structure.
                  <br />
                  <br />
                  In our 90 years of experience working with Canadian
                  businesses, we continue to work with clients year after year
                  to address their most pressing questions and issues:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Determining the most appropriate capital structure for
                        their business
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Evaluating capital sources, and determining the best
                        options for their business
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Analyzing the value of their business and representing
                        that value to potential sources of capital
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Ensuring financing partners are suitable for the
                        long-term strategic vision of their business{" "}
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Securing the most reasonable financing terms and
                        conditions
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Protecting the business from unacceptable levels of risk
                      </p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>
                        Developing and maintaining open and trusted
                        relationships in financial relationships
                      </p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Maximizing the opportunities available to your business
                  through financing options requires a comprehensive
                  understanding of any combination of the following:
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>

        <div className="mt-12 px-4">
          <InView threshold={0.7} triggerOnce>
            {({ ref, inView }) => (
              <AnimatePresence exitBeforeEnter>
                <motion.ul
                  ref={ref}
                  initial={{ opacity: 0, x: -100 }}
                  animate={
                    inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                  }
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 0.6 }}
                >
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Capital structures</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Capital sources</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Business valuation</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Risk assessment and management</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Due diligence</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Partner identification and assessment</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Financial communications</p>
                    </div>
                  </motion.li>
                  <motion.li
                    ref={ref}
                    initial={{ opacity: 0, y: 100 }}
                    animate={
                      inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                    }
                    transition={{ duration: 0.8 }}
                    className="mb-4 text-sm md:text-lg text-primary-dark font-medium leading-loose "
                  >
                    <div className="flex items-start justify-start">
                      <img
                        src="./icons/bullet.svg"
                        alt="bullet-point"
                        className="w-4 h-4 mr-2 mt-1"
                      />
                      <p>Financial modeling</p>
                    </div>
                  </motion.li>
                </motion.ul>
              </AnimatePresence>
            )}
          </InView>
        </div>

        <InView threshold={0.5} triggerOnce>
          {({ ref, inView }) => (
            <AnimatePresence exitBeforeEnter>
              <motion.div
                ref={ref}
                initial={{ opacity: 0, x: -100 }}
                animate={
                  inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -100 }
                }
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="mt-16"
              >
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, y: 100 }}
                  animate={
                    inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 100 }
                  }
                  transition={{ duration: 0.8 }}
                  className="text-sm md:text-lg text-primary-dark font-medium leading-loose"
                >
                  Access to capital and the management of capital is an
                  essential element in the overall health of any business. Know
                  your options and seek the expertise to make the most of them.
                </motion.p>
              </motion.div>
            </AnimatePresence>
          )}
        </InView>
      </section>
    </div>
  );
}

export default Financing;
