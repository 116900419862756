import React from "react";

function DiscoverCard({ classes, title, iconSrc }) {
  return (
    <div
      className={`max-w-300px md:max-w-350px pt-4 md:pt-0 md:py-8  flex flex-col items-center justify-center text-center rounded-3xl ${classes}`}
    >
      <div className="mb-8 bg-secondary-lighter rounded-full w-20 h-20 md:w-24 md:h-24 flex justify-center items-center shadow-lg">
        <img src={iconSrc} alt="" className="max-w-65px md:max-w-95px" />
      </div>
      <h4 className="text-xl md:text-2xl font-bold capitalize mb-4 tracking-normal">
        {title}
      </h4>
    </div>
  );
}

export default DiscoverCard;
